.dropdown-button {
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdownList :hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.disabled {
  background-color: #bcbcbc;
  border-color: #bcbcbc;
  color: #54575a;
  cursor: not-allowed;
}
.dropdown-noScrollbar::-webkit-scrollbar {
  display: none;
}
.dropdown-noScrollbar {
    -ms-overflow-style: none;

}
