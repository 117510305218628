.neutron-table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    color: #54575a;
}

.neutron-table__row {
    width: 100%;
}

.neutron-table__row:hover {
    background: #d9d9d6;
}

.neutron-table__row:focus,
.neutron-table__row:active {
    background: #bfe0f2;
    border-color: #0085ca;
}

.neutron-table__row.neutron-table__cell.neutron-table__row-compact {
    padding: 0.313rem 0.938rem !important;
}

.neutron-table__row.neutron-table__cell.neutron-table__row-comfy {
    padding: 0.625rem 0.938rem !important;
}

.neutron-table__row.neutron-table__cell.neutron-table__row-cozy {
    padding: 0.938rem !important;
}

.neutron-table__header {
    font-family: "HCA-Mark-Medium", "Arial", sans-serif;
    text-align: left;
    color: #54575a;
    text-transform: capitalize;
    font-size: 0.875em;
}

.neutron-table__header .neutron-table__sort-icon {
    vertical-align: bottom;
    color: #898b8e;
}

.neutron-table__tablist {
    height: 40px;
    width: 100%;
    justify-content: flex-start;
    border-bottom: 0.063rem solid #bcbcbc;
}

.neutron-table__header,
.neutron-table__cell,
.neutron-table__body,
.neutron-table__foot,
.neutron-table__head {
    border-bottom: 0.063rem solid #bcbcbc;
    padding: 0.938rem;
}
