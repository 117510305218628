.neutron-nav {
  min-width: 100%;
  padding: 0 2.813rem;
  font-size: 18px;
  display: flex;
  flex-direction: row;
}

.neutron-nav-light {
  height: 60px;
  background: #ffffff;
  box-shadow: 0em -0.5em 0.5em -0.5em rgba(31, 37, 50, 0.2);
  color: #03173e;
}

.neutron-topnav--dark {
  height: 60px;
  background: #03173e;
  color: #ffffff;
}

.neutron-topnav--compact {
  background: #03173e;
  color: #ffffff;
  vertical-align: middle;
}

.neutron-left-elements {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  align-items: center;
  flex-grow: 1;
}

.neutron-topnav--compact__text {
  text-decoration: none;
}

.neutron-text--white {
  color: #ffffff !important;
}
.neutron-text--product {
  font-size: 1em;
  line-height: 1.625rem;
  font-family: 'HCA-Mark-Bold';
}

.neutron-topnav--compact__username {
  font-size: 0.875em;
  text-decoration: none;
}

.neutron-topnav--user-profile-on-dark {
  padding: 8px;
  align-content: center;
}
.neutron-text--white {
  color: #ffffff !important;
}
.neutron-margin--right-10 {
  margin-right: 0.625em !important;
}
.d-inline-block {
  display: inline-block !important;
}

.neutron-right-elements {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.neutron-topnav__notifications-on-dark {
  padding: 8px;
  text-decoration: none;
  color: #ffffff;
}

/*@media screen and (min-width: 768px) {*/
  /*.NeutronComponents-nav {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*padding-bottom: 0;*/
    /*height: 70px;*/
    /*align-items: center;*/
  /*}*/

  /*.NeutronComponents-nav {*/
    /*min-width: 100%;*/
    /*height: 70px;*/
    /*background: #ffffff;*/
    /*padding: 0 2.813rem;*/
    /*box-shadow: inset 0em -0.5em 0.5em -0.5em rgba(31, 37, 50, 0.2);*/
    /*font-size: 18px;*/
    /*color: #03173e;*/
  /*}*/
/*}*/

.neutron-navbar--search {
  display: flex;
}
