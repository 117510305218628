/* ==========================================================================
   Neutron Pagination
   ========================================================================== */

/**
 * Basic States:
 *
 * IMPORTANT:
 *
 * TODO: neutron dropdown customization; display count needs to reference dropdown
 */

/* ROWS PER PAGE */

.neutron-pagination__rows-per-page {
  display: flex;
  /* SELECTION CONTAINER*/
}

.neutron-pagination__rows-per-page p {
  display: inline-block;
}

.neutron-pagination__rows-per-page .neutron-dropdown--simple {
  display: inline-block;
  vertical-align: middle;
}

/* DISPLAY COUNT */

.neutron-pagination__display-count {
  display: inline-block;
}

/* PAGINATION SET */

.neutron-pagination__set {
  vertical-align: middle;
  /* PAGE NUMBERS */
}

.neutron-pagination {
  display: inline-block;
  padding: 0 40px;
}

.neutron-pagination ul {
  list-style: none;
  padding: 0;
}

.neutron-pagination ul li a {
  color: #1f2532;
  text-decoration: none;
  margin: 0rem 0.313rem;
  background-color: #d9d9d6;
  border-radius: 50%;
  width: 2.125rem;
  height: 2.125rem;
  line-height: 2rem;
  display: inline-block;
  text-align: center;
}

.neutron-pagination ul li a i {
  line-height: 2.125rem;
  vertical-align: bottom;
}

.neutron-pagination ul li a:hover:not(.neutron-pagination__active) {
  border: 0.063rem solid #03173e;
}

.neutron-pagination ul li a:focus {
  background-color: #005589;
  color: #ffffff;
}

.neutron-pagination ul li .neutron-pagination__active {
  background-color: #005589;
  color: #ffffff;
}

.neutron-pagination__set
  .neutron-pagination
  ul
  li
  .neutron-pagination__overflow {
  line-height: 2.125rem;
  vertical-align: bottom;
  cursor: default;
}

/* TODO: CREATE DROPDOWN COMPONENT */

.neutron-dropdown--simple {
  padding: 0;
  margin: 0;
  width: 3.125rem;
  border-radius: 0.188rem;
  overflow: hidden;
  background: transparent;
  position: relative;
}

.neutron-dropdown--simple:hover {
  background-color: #d9d9d6;
}

.neutron-dropdown--simple:after {
  top: 50%;
  left: 65%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(137, 139, 142, 0);
  border-top-color: #898b8e;
  border-width: 0.313rem;
  margin-top: -0.125rem;
}

.neutron-dropdown--simple .neutron-dropdown__content {
  padding: 0.313rem 0.5rem;
  width: 130%;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1000;
}

.neutron-dropdown--simple .neutron-dropdown__content:focus {
  outline: none;
}
