html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

.accordion-categories-container:first-of-type {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.accordion-categories-container:last-of-type {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
